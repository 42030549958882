<template>
  <div class="owner">
    <div class="wrap">
      <div class="link">
        <router-link :to="{ name: 'anicom-reports' }" data-test="link"
          ><base-back-link
        /></router-link>
      </div>
      <div class="title-wrap">
        <div class="title">レセプト詳細</div>
        <div class="buttons-wrap">
          <base-button-border-orange
            class="button"
            :styles="{ width: '120px', height: '30px' }"
            :disabled="deletedKarteFlg === 1"
            @click="pushToKarte"
            data-test="karte-button"
            >カルテ</base-button-border-orange
          >
          <base-button-border-orange
            class="button"
            :styles="{ width: '120px', height: '30px' }"
            :disabled="deletedMedicalPaymentFlg === 1"
            @click="pushToPayment"
            data-test="payment-button"
            >会計</base-button-border-orange
          >
          <base-button-small-orange
            v-if="lookOnlyFlg === 0"
            class="button"
            :styles="{ width: '120px', height: '30px' }"
            :disabled="waitFlg || !(sentStatus === '未送信（送信可）')"
            @click="sendAnicomRezept"
            data-test="sent-button"
            >送信</base-button-small-orange
          >
          <base-button-small-red
            v-if="lookOnlyFlg === 0"
            class="button"
            :styles="{ width: '120px', height: '30px' }"
            :disabled="!(sentStatus === '送信済（キャンセル可）')"
            @click="openCancelPopup"
            data-test="cancel-button"
            >キャンセル</base-button-small-red
          >
        </div>
      </div>
      <div class="contents">
        <div v-if="changedMedicalPaymentFlg" class="red">診療明細変更済み</div>
        <anicom-show-table v-bind="displayColumns" :sentStatus="sentStatus" />
        <div class="warning red" data-test="warning">
          <div v-if="deletedMedicalPaymentFlg === 1">
            ※診療明細が削除されました。
          </div>
          <div v-else-if="paymentStatusError.length > 0">
            ※{{ paymentStatusError }}になっています。
          </div>
        </div>
      </div>
    </div>
    <announce-popup
      v-if="popup.alertFlg"
      :type="popup.type"
      :title="popup.title"
      :buttons="popup.buttons"
      :disabled="waitFlg"
      @close="popup.close"
      @cancel="popup.alertFlg = false"
      @decision="cancelAnicomRezept"
      >{{ popup.message }}</announce-popup
    >
  </div>
</template>

<script>
import BaseBackLink from '@/components/parts/atoms/BaseBackLink'
import BaseButtonBorderOrange from '@/components/parts/atoms/BaseButtonBorderOrange'
import BaseButtonSmallOrange from '@/components/parts/atoms/BaseButtonSmallOrange'
import BaseButtonSmallRed from '@/components/parts/atoms/BaseButtonSmallRed'
import AnicomShowTable from '@/components/parts/organisms/AnicomShowTable'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import moment from 'moment'
import { mapGetters } from 'vuex'
import {
  makeHospitalizationDatum,
  makeHospitalizationHistory
} from '@/utils/hospitalization_history'
import { makePaymentDetail } from '@/utils/price_calculation'
import { makeAnicomRezeptResponse } from '@/utils/anicom_response'

export default {
  name: 'AnicomReportShow',

  components: {
    BaseBackLink,
    BaseButtonBorderOrange,
    BaseButtonSmallOrange,
    BaseButtonSmallRed,
    AnicomShowTable,
    AnnouncePopup
  },

  props: {
    medicalPaymentId: { type: Number }
  },

  data() {
    return {
      displayColumns: {},
      sentStatus: '',
      deletedMedicalPaymentFlg: 0,
      deletedKarteFlg: 0,
      paymentStatusError: '',
      popup: {
        alertFlg: false,
        type: '',
        title: '',
        message: '',
        buttons: [],
        close: () => {}
      },
      timeoutId: null,
      changedMedicalPaymentFlg: false,
      waitFlg: false
    }
  },

  computed: {
    ...mapGetters({
      getMedicalPayment: 'medicalPayments/getDataById',
      getMedicalPaymentByPatientId: 'medicalPayments/getDataByPatientId',
      getAnicomCIdCheck: 'anicomCIdChecks/getDataById',
      getAnicomReports: 'anicomReports/getDataByMedicalPaymentIdIncludeDel',
      getPatient: 'patients/getDataById',
      getOwner: 'owners/getDataById',
      getMedicalRecordByOriginalId: 'medicalRecords/getDataByOriginalId',
      getMedicalContentsByMedicalRecordOriginalId:
        'medicalContents/getDataByMedicalRecordOriginalId',
      getDiseaseClass: 'diseaseClasses/getDataById',
      getDisease: 'diseases/getDataById',
      getMedicalPaymentHistory: 'medicalPayments/getDataByOriginalIdIncludeDel',
      getPaymentsByMedicalPaymentId: 'payments/getDataByMedicalPaymentId',
      lookOnlyFlg: 'auth/lookOnlyFlg',
      medicalPayments: 'medicalPayments/getData',
      payments: 'payments/getData',
      owners: 'owners/getData',
      patients: 'patients/getData',
      anicomReports: 'anicomReports/getData'
    })
  },

  watch: {
    medicalPayments: function() {
      this.setDisplayColumns()
    },
    payments: function() {
      this.setDisplayColumns()
    },
    owners: function() {
      this.setDisplayColumns()
    },
    patients: function() {
      this.setDisplayColumns()
    },
    anicomReports: function() {
      this.setDisplayColumns()
    }
  },

  async created() {
    if (!this.getMedicalPayment(this.medicalPaymentId)) {
      const res = await this.$store.dispatch(
        'medicalPayments/fetchRelatedById',
        this.medicalPaymentId
      )
      if (res === 'success') {
        this.setDisplayColumns()
      } else if (res === 'no data') {
        this.popup = {
          alertFlg: true,
          type: 'failure',
          title: '失敗',
          message: '診療明細が既に削除されています。',
          buttons: ['閉じる'],
          close: () => this.$router.push('/main/anicom-reports')
        }
      } else if (res === 'server error') {
        this.popup = {
          alertFlg: true,
          type: 'failure',
          title: '失敗',
          message: '通信エラーが発生しました。',
          buttons: ['閉じる'],
          close: () => this.closePopup()
        }
      }
    } else {
      this.setDisplayColumns()
    }

    // ミリ秒単位まで一緒だと期待した再描画にならない可能性があるので5秒余裕を持たせる。
    this.timeoutId = window.setTimeout(() => {
      const medicalPayment = this.getMedicalPayment(this.medicalPaymentId)
      const anicomReports = this.getAnicomReports(medicalPayment.id)
      if (Array.isArray(anicomReports) && anicomReports.length > 0) {
        const anicomReport = anicomReports[anicomReports.length - 1]
        anicomReport.isValid =
          anicomReport.resultFlg === 1 && anicomReport.httpStatus === 200
        this.makeSentInfo(anicomReport, medicalPayment)
      } else {
        this.makeSentInfo(null, medicalPayment)
      }
    }, moment().diff(moment('0305', 'HHss')))
  },

  beforeDestroy() {
    clearTimeout(this.timeoutId)
  },

  methods: {
    setDisplayColumns() {
      const medicalPayment = this.getMedicalPayment(this.medicalPaymentId)
      const anicomReport = this.findAnicomReport(medicalPayment)
      if (
        medicalPayment.delFlg === 1 &&
        (!anicomReport ||
          (anicomReport.delFlg === 1 && anicomReport.cancelFlg === 1))
      ) {
        this.popup = {
          alertFlg: true,
          type: 'failure',
          title: '失敗',
          message: '診療明細が削除されました。',
          buttons: ['閉じる'],
          close: () => this.$router.push('/main/anicom-reports')
        }
        return
      }
      this.setDeletedStatus(medicalPayment)
      this.setPaymentStatusError(anicomReport, medicalPayment)
      this.displayColumns = anicomReport?.isValid
        ? this.makeDisplayColumnsFromAnicomReport(anicomReport, medicalPayment)
        : this.makeDisplayColumnsFromMedicalPayment(
            anicomReport,
            medicalPayment
          )
    },
    setDeletedStatus(medicalPayment) {
      this.deletedMedicalPaymentFlg = medicalPayment.delFlg
      const medicalContents =
        this.getMedicalContentsByMedicalRecordOriginalId(
          medicalPayment.medicalRecordOriginalId
        ) ?? []
      const existingMedicalContents = medicalContents.filter(
        v => v.delFlg === 0
      )
      this.deletedKarteFlg =
        medicalPayment.delFlg === 1 && existingMedicalContents.length === 0
    },
    setPaymentStatusError(anicomReport, medicalPayment) {
      const sentAnicomReport =
        anicomReport &&
        anicomReport.delFlg === 0 &&
        anicomReport.cancelFlg === 0 &&
        medicalPayment.delFlg === 0
      if (sentAnicomReport) {
        const paymentStatus = makePaymentDetail(
          medicalPayment,
          this.getMedicalPaymentHistory,
          this.getMedicalRecordByOriginalId,
          this.getPaymentsByMedicalPaymentId
        ).type
        this.paymentStatusError =
          paymentStatus === '会計済' ? '' : paymentStatus
      }
    },
    makeDisplayColumnsFromAnicomReport(anicomReport, medicalPayment) {
      const { lastSentDate, limitDate } = this.makeSentInfo(
        anicomReport,
        medicalPayment
      )
      const noApplyReasons = this.makeNoApplyReasons(
        anicomReport,
        medicalPayment
      )
      return {
        lastSentDate,
        limitDate,
        noApplyReasons,
        rezeptCdDate: this.rewriteDate(anicomReport.rezeptCdDate),
        rezeptCd: anicomReport.rezeptCd,
        anicomCId: anicomReport.cId,
        isHospitalization: anicomReport.hisDate ? '入院' : '通院',
        onsetDate: this.toOnsetDate({ anicomReport }),
        medicalTreatmentDate: this.rewriteDate(anicomReport.dicDate),
        surgeryDate: this.rewriteDate(anicomReport.opDate),
        hospitalizationStartDate: this.rewriteDate(anicomReport.hisDate),
        hospitalizationEndDate: this.rewriteDate(anicomReport.hieDate),
        ownerLastName: anicomReport.cSname,
        patientName: anicomReport.aniName,
        diagnosis1: anicomReport.diagnosis1,
        diagnosis2: anicomReport.diagnosis2,
        medicalExpenses: '￥' + anicomReport.dicAmount.toLocaleString(),
        pledgeRate: anicomReport.pledgeRate + '%',
        taxIncludedInsuranceCoveredPrice:
          '￥' + anicomReport.tgtAmount.toLocaleString(),
        insurancePrice: '￥' + anicomReport.insAmount.toLocaleString()
      }
    },
    makeDisplayColumnsFromMedicalPayment(anicomReport, medicalPayment) {
      const { lastSentDate, limitDate } = this.makeSentInfo(
        anicomReport,
        medicalPayment
      )
      const noApplyReasons = this.makeNoApplyReasons(
        anicomReport,
        medicalPayment
      )
      const { rezeptCdDate, rezeptCd, anicomCId } = this.makeRezeptInfo(
        medicalPayment
      )
      const {
        isHospitalization,
        medicalTreatmentDate,
        surgeryDate,
        hospitalizationStartDate,
        hospitalizationEndDate
      } =
        medicalPayment.endHospitalizationFlg === 1
          ? this.makeInHospitalizationInfo(medicalPayment)
          : this.makeOutpatientInfo(medicalPayment)
      const patient = this.getPatient(medicalPayment.patientId)
      const owner = this.getOwner(patient.ownerId)
      const diseaseClass1 = this.getDiseaseClass(medicalPayment.diseaseClass1Id)
      const diseaseClass2 = this.getDiseaseClass(medicalPayment.diseaseClass2Id)
      const disease1 = this.getDisease(medicalPayment.disease1Id)
      const disease2 = this.getDisease(medicalPayment.disease2Id)
      return {
        lastSentDate,
        limitDate,
        noApplyReasons,
        rezeptCdDate,
        rezeptCd,
        anicomCId,
        isHospitalization,
        onsetDate: this.toOnsetDate({ medicalPayment }),
        medicalTreatmentDate,
        surgeryDate,
        hospitalizationStartDate,
        hospitalizationEndDate,
        ownerLastName: owner.lastName,
        patientName: patient.name,
        diagnosis1: this.toDiagnosis(diseaseClass1, diseaseClass2),
        diagnosis2: this.toDiagnosis(disease1, disease2),
        medicalExpenses:
          '￥' + medicalPayment.taxIncludedPrice.toLocaleString(),
        pledgeRate: medicalPayment.pledgeRate + '%',
        taxIncludedInsuranceCoveredPrice:
          '￥' +
          medicalPayment.taxIncludedInsuranceCoveredPrice.toLocaleString(),
        insurancePrice: '￥' + medicalPayment.insurancePrice.toLocaleString()
      }
    },
    findAnicomReport(medicalPayment) {
      const medicalPayments = this.getMedicalPaymentHistory(
        medicalPayment.originalId
      )
      let anicomReport
      for (const v of medicalPayments) {
        const anicomReports = this.getAnicomReports(v.id) ?? []
        if (anicomReports.length) {
          const latest = anicomReports.at(-1)
          const isValid = this.isSentAnicomReport(latest)
          anicomReport = { ...latest, isValid }
          if (isValid) break
        } else {
          anicomReport = null
        }
      }
      return anicomReport
    },
    isSentAnicomReport(anicomReport) {
      return (
        anicomReport.resultFlg === 1 &&
        anicomReport.httpStatus === 200 &&
        anicomReport.cancelFlg === 0
      )
    },
    makeRezeptInfo(medicalPayment) {
      const anicomCIdCheck = this.getAnicomCIdCheck(
        medicalPayment.anicomCIdCheckId
      )
      const rezeptCdDate = this.rewriteDate(anicomCIdCheck.date)
      const rezeptCd = medicalPayment.rezeptCd
      const anicomCId = anicomCIdCheck.patientCId
      return { rezeptCdDate, rezeptCd, anicomCId }
    },
    makeSentInfo(anicomReport, medicalPayment) {
      if (anicomReport && anicomReport.delFlg === 0) {
        if (medicalPayment.id !== anicomReport.medicalPaymentId) {
          this.changedMedicalPaymentFlg = true
        }
        if (anicomReport.isValid) {
          const lastSentDate = this.rewriteDateTime(anicomReport.sendDate)
          const sentDate = moment(anicomReport.sendDate).format('YYYY-MM-DD')
          const limitDate = moment(anicomReport.sendDate).isBetween(
            sentDate + ' 00:00',
            sentDate + ' 03:00',
            'hour'
          )
            ? this.rewriteLimitDate(
                moment(anicomReport.sendDate).format('YYYYMMDD') + '03'
              )
            : this.rewriteLimitDate(
                moment(anicomReport.sendDate)
                  .add(1, 'd')
                  .format('YYYYMMDD') + '03'
              )
          this.sentStatus = moment().isBefore(moment(limitDate, 'YYYYMMDDHH'))
            ? '送信済（キャンセル可）'
            : '送信済（再送信不可）'
          return { lastSentDate, limitDate }
        } else {
          this.sentStatus = '未送信（送信可）'
          return { lastSentDate: '', limitDate: '' }
        }
      } else {
        const paymentStatus = makePaymentDetail(
          medicalPayment,
          this.getMedicalPaymentHistory,
          this.getMedicalRecordByOriginalId,
          this.getPaymentsByMedicalPaymentId
        ).type
        this.sentStatus =
          paymentStatus === '再会計'
            ? '未送信（再会計）'
            : paymentStatus === '未会計'
            ? '未送信（未会計）'
            : paymentStatus === '未収金'
            ? '未送信（未収金）'
            : '未送信（送信可）'
        return { lastSentDate: '', limitDate: '' }
      }
    },
    makeInHospitalizationInfo(medicalPayment) {
      const medicalRecord = this.getMedicalRecordByOriginalId(
        medicalPayment.medicalRecordOriginalId
      )
      const medicalPayments = this.getMedicalPaymentByPatientId(
        medicalPayment.patientId
      )
      const hospitalizationData = medicalPayments.map(payment => {
        const record = this.getMedicalRecordByOriginalId(
          payment.medicalRecordOriginalId
        )
        return {
          ...makeHospitalizationDatum(payment, record),
          surgeryFlg: payment.surgeryFlg
        }
      })
      const hospitalizationHistory = makeHospitalizationHistory(
        hospitalizationData
      ).find(v => v.endDate === medicalRecord.date)
      const surgeryDateDatum = hospitalizationData.find(v => v.surgeryFlg === 1)
      const surgeryDate = surgeryDateDatum
        ? this.rewriteDate(surgeryDateDatum.date)
        : ''
      const hospitalizationStartDate = this.rewriteDate(
        hospitalizationHistory.startDate
      )
      const hospitalizationEndDate = this.rewriteDate(
        hospitalizationHistory.endDate
      )
      return {
        isHospitalization: '入院',
        medicalTreatmentDate: '',
        surgeryDate,
        hospitalizationStartDate,
        hospitalizationEndDate
      }
    },
    makeOutpatientInfo(medicalPayment) {
      const medicalRecord = this.getMedicalRecordByOriginalId(
        medicalPayment.medicalRecordOriginalId
      )
      const medicalTreatmentDate = this.rewriteDate(medicalRecord.date)
      const surgeryDate =
        medicalPayment.surgeryFlg === 1 ? medicalTreatmentDate : ''
      return {
        isHospitalization: '通院',
        medicalTreatmentDate,
        surgeryDate,
        hospitalizationStartDate: '',
        hospitalizationEndDate: ''
      }
    },
    toDiagnosis(a, b) {
      return [a?.name, b?.name].filter(v => !!v).join(',')
    },
    toOnsetDate({ anicomReport, medicalPayment }) {
      const { onsetDate, onsetAroundFlg = false } =
        anicomReport ?? medicalPayment
      return onsetDate.includes('頃')
        ? this.rewriteAroundDate(onsetDate.substring(0, 7))
        : onsetAroundFlg
        ? this.rewriteAroundDate(onsetDate)
        : this.rewriteDate(onsetDate)
    },
    makeNoApplyReasons(anicomReport, medicalPayment) {
      const reasons = [
        '予防',
        '去勢・避妊',
        '日常ケア・フード',
        '妊娠・出産',
        '臍・鼠径ヘルニア',
        '美容',
        '医薬品以外',
        anicomReport ? anicomReport.reason09Txt : medicalPayment.reason09Txt
      ]
      return reasons.reduce((str, val, i) => {
        const reason =
          anicomReport?.isValid && anicomReport[`reason0${i + 1}`] === 1
            ? val
            : medicalPayment[`reason0${i + 1}`] === 1
            ? val
            : ''
        str += str !== '' && reason !== '' ? '、' + reason : reason
        return str
      }, '')
    },
    rewriteDate(YYYYMMDD) {
      return YYYYMMDD
        ? moment(YYYYMMDD, 'YYYYMMDD').format('YYYY年MM月DD日')
        : ''
    },
    rewriteDateTime(date) {
      return moment(date).format('YYYY年MM月DD日 HH:mm')
    },
    rewriteAroundDate(date) {
      return moment(date, 'YYYYMM').format('YYYY年MM月 頃')
    },
    rewriteLimitDate(YYYYMMDDHH) {
      return moment(YYYYMMDDHH, 'YYYYMMDDHH').format('YYYY年MM月DD日 HH:00')
    },
    pushToKarte() {
      const medicalPayment = this.getMedicalPayment(this.medicalPaymentId)
      const patient = this.getPatient(medicalPayment.patientId)
      const owner = this.getOwner(patient.ownerId)
      const medicalRecord = this.getMedicalRecordByOriginalId(
        medicalPayment.medicalRecordOriginalId
      )
      this.$router.push({
        path: `/main/karte/owners/${owner.id}/patients/${patient.id}/medical-records/${medicalRecord.originalId}/edit`
      })
    },
    pushToPayment() {
      const medicalPayment = this.getMedicalPayment(this.medicalPaymentId)
      const patient = this.getPatient(medicalPayment.patientId)
      const owner = this.getOwner(patient.ownerId)
      const paymentStatus = makePaymentDetail(
        medicalPayment,
        this.getMedicalPaymentHistory,
        this.getMedicalRecordByOriginalId,
        this.getPaymentsByMedicalPaymentId
      ).type
      const branchPath = paymentStatus === '未会計' ? 'new' : 'show'
      this.$router.push({
        path: `/main/karte/owners/${owner.id}/patients/${patient.id}/payments/${medicalPayment.originalId}/${branchPath}`
      })
    },
    async sendAnicomRezept() {
      this.waitFlg = true
      const res = await this.$store.dispatch('anicomReports/create', [
        this.medicalPaymentId
      ])
      if (res.status === 200) {
        // 送信情報を更新
        const medicalPayment = this.getMedicalPayment(this.medicalPaymentId)
        const anicomReport = res.anicomReports[0]
        anicomReport.isValid = true
        if (anicomReport.resultFlg === 1 && anicomReport.httpStatus === 200) {
          this.popup = {
            alertFlg: true,
            type: 'success',
            title: '成功',
            message: 'レセプトを送信しました。',
            buttons: ['閉じる'],
            close: () => this.closePopup()
          }
          this.makeSentInfo(anicomReport, medicalPayment)
        } else {
          const regex = new RegExp('<!--ng_item .*? ng_item-->')
          const ngItems = anicomReport.result.match(regex)[0]
          const message = makeAnicomRezeptResponse(ngItems)
          this.popup = {
            alertFlg: true,
            type: 'failure',
            title: '失敗',
            message,
            buttons: ['閉じる'],
            close: () => this.closePopup()
          }
        }
      } else {
        this.popup = {
          alertFlg: true,
          type: 'failure',
          title: '失敗',
          message: '通信エラーが発生しました。',
          buttons: ['閉じる'],
          close: () => this.closePopup()
        }
      }
      this.waitFlg = false
    },
    openCancelPopup() {
      this.popup = {
        alertFlg: true,
        type: 'alert',
        title: '注意',
        message: 'キャンセルしてもよろしいですか？',
        buttons: ['キャンセルしない', 'キャンセルする'],
        close: () => {}
      }
    },
    async cancelAnicomRezept() {
      this.waitFlg = true
      const anicomReports = this.getAnicomReports(this.medicalPaymentId)
      const anicomReportId = anicomReports[anicomReports.length - 1].id
      const res = await this.$store.dispatch(
        'anicomReports/cancel',
        anicomReportId
      )
      const medicalPayment = this.getMedicalPayment(this.medicalPaymentId)
      this.makeSentInfo(null, medicalPayment)
      if (res === 'success') {
        this.popup = {
          alertFlg: true,
          type: 'success',
          title: '成功',
          message: 'レセプトをキャンセルしました。',
          buttons: ['閉じる'],
          close: () => this.closePopup()
        }
      } else {
        const message =
          res === 'no data'
            ? '既に削除またはキャンセルされています。'
            : res === 'limit over'
            ? 'オンライン請求の取消締切(翌日午前3時)を過ぎています。'
            : '通信エラーが発生しました。'
        this.popup = {
          alertFlg: true,
          type: 'failure',
          title: '失敗',
          message,
          buttons: ['閉じる'],
          close: () => this.closePopup()
        }
      }
      this.waitFlg = false
    },
    closePopup() {
      this.popup = {
        alertFlg: false,
        type: '',
        title: '',
        message: '',
        buttons: [],
        close: () => {}
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.owner {
  width: 100%;
  text-align: left;
  overflow: auto;
  > .wrap {
    min-width: 710px;
    padding: 24px 48px;
    box-sizing: border-box;
    > .link {
      margin-left: -26px;
    }
    > .title-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 33px;
      margin-top: 20px;
      border-bottom: 1px solid #{$light-grey};
      padding-bottom: 20px;
      > .title {
        font-size: 20px;
        font-weight: bold;
      }
      > .buttons-wrap {
        display: flex;
        > .button {
          margin-left: 20px;
        }
      }
    }

    > .contents {
      margin-top: 20px;
      > .red {
        font-size: 15px;
        color: #{$tomato};
      }
      > .warning {
        margin-top: 5px;
      }
      > .show-data-list {
        width: 50%;
      }
    }
  }
}
</style>
