<template>
  <div class="anicom-show-table">
    <table border="1">
      <tr>
        <th>承認番号発行日</th>
        <td data-test="rezept-cd-date">{{ rezeptCdDate }}</td>
        <th>承認番号</th>
        <td data-test="rezept-cd">{{ rezeptCd }}</td>
      </tr>
      <tr>
        <th>送信状況</th>
        <td data-test="sent-status">{{ sentStatus }}</td>
        <th>最終送信日時</th>
        <td data-test="last-sent-date">{{ lastSentDate }}</td>
      </tr>
      <tr>
        <th>証券番号</th>
        <td data-test="anicom-c-id">{{ anicomCId }}</td>
        <th>再送信締切日時</th>
        <td data-test="limit-date">{{ limitDate }}</td>
      </tr>
      <tr>
        <th>通院/入院区分</th>
        <td data-test="is-hospitalization">{{ isHospitalization }}</td>
        <th>受傷日/発症日</th>
        <td data-test="onset-date">{{ onsetDate }}</td>
      </tr>
      <tr>
        <th>診療日</th>
        <td data-test="medical-treatment-date">{{ medicalTreatmentDate }}</td>
        <th>手術日</th>
        <td data-test="surgery-date">{{ surgeryDate }}</td>
      </tr>
      <tr>
        <th>入院日</th>
        <td data-test="hospitalization-start-date">
          {{ hospitalizationStartDate }}
        </td>
        <th>退院日</th>
        <td data-test="hospitalization-end-date">
          {{ hospitalizationEndDate }}
        </td>
      </tr>
      <tr>
        <th>飼主名</th>
        <td colspan="3" data-test="owner-last-name">{{ ownerLastName }}</td>
      </tr>
      <tr>
        <th>患者名</th>
        <td colspan="3" data-test="patient-name">{{ patientName }}</td>
      </tr>
      <tr>
        <th>傷病名分類</th>
        <td colspan="3" data-test="diagnosis-1">{{ diagnosis1 }}</td>
      </tr>
      <tr>
        <th>傷病名</th>
        <td colspan="3" data-test="diagnosis-2">{{ diagnosis2 }}</td>
      </tr>
      <tr>
        <th>診療費</th>
        <td data-test="medical-expenses">{{ medicalExpenses }}</td>
        <th>支払い割合</th>
        <td data-test="pledge-rate">{{ pledgeRate }}</td>
      </tr>
      <tr>
        <th>保険補償対象額</th>
        <td data-test="tax-included-insurance-covered-price">
          {{ taxIncludedInsuranceCoveredPrice }}
        </td>
        <th>保険金請求額</th>
        <td data-test="insurance-price">{{ insurancePrice }}</td>
      </tr>
      <tr>
        <th>保険対象外理由</th>
        <td colspan="3" data-test="no-apply-reasons">{{ noApplyReasons }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  name: 'AnicomShowTable',

  props: {
    rezeptCdDate: { type: String, default: '' },
    rezeptCd: { type: String, default: '' },
    sentStatus: { type: String, default: '' },
    lastSentDate: { type: String, default: '' },
    anicomCId: { type: String, default: '' },
    limitDate: { type: String, default: '' },
    isHospitalization: { type: String, default: '' },
    onsetDate: { type: String, default: '' },
    medicalTreatmentDate: { type: String, default: '' },
    surgeryDate: { type: String, default: '' },
    hospitalizationStartDate: { type: String, default: '' },
    hospitalizationEndDate: { type: String, default: '' },
    ownerLastName: { type: String, default: '' },
    patientName: { type: String, default: '' },
    diagnosis1: { type: String, default: '' },
    diagnosis2: { type: String, default: '' },
    medicalExpenses: { type: String, default: '' },
    pledgeRate: { type: String, default: '' },
    taxIncludedInsuranceCoveredPrice: { type: String, default: '' },
    insurancePrice: { type: String, default: '' },
    noApplyReasons: { type: String, default: '' }
  }
}
</script>

<style lang="scss" scoped>
.anicom-show-table {
  table {
    border-collapse: collapse;
    border-color: #{$light-grey};
    th,
    td {
      font-size: 15px;
      padding: 5px 10px;
    }
    th {
      width: 120px;
      background-color: #{$pale};
      font-weight: normal;
    }
    td {
      width: 300px;
      max-width: 300px;
      overflow-wrap: break-word;
    }
  }
}
</style>
